import Cabecera from "./Cabecera/Cabecera";
import Contenido from "./Contenido/Contenido";
import "./WeLoveCoffee.css";

function WeLoveCoffee() {
  return (
    <>
      <Cabecera />
      <Contenido />
    </>
  );
}

export default WeLoveCoffee;
